<mat-dialog-content>
  <h3>{{data.action | titlecase}} Custom Column</h3>

  <mat-form-field class="zpx-form-field" appearance="fill">
    <mat-label>Custom Column Name</mat-label>
    <input
      #customColumnNameInput
      matInput
      type="text"
      [formControl]="formControl" />
    <mat-error *ngIf="formControl.hasError('required')">
      Column Name <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-dialog-actions [style]="'margin-top:auto; padding-bottom:1.5rem'">
    <div class="button-container">
      <button
        class="mat-stroked-button mat-button-base"
        [ngClass]="(disabled | async) ? 'mat-button-disabled':  'accent-button'"
        [disabled]="disabled | async"
        (click)="saveChange()">
        {{buttonActionMap[data.action]}}
      </button>
      <button
        class="mat-stroked-button mat-button-base white-button"
        mat-dialog-close>
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</mat-dialog-content>
