<div id="manage-group-modal">
  <mat-dialog-content>
    <div fxLayout="row">
      <h2>Manage Groups</h2>
      <mat-icon class="mat-accent">info</mat-icon>
    </div>

    <div>
      <div fxFlex>
        <div fxLayout="row" fxLayoutGap="3em">
          <div class="first-column" fxLayout="column">
            <button class="bottom-padding mat-button accent-button">
              + Add new group
            </button>
            <app-zpx-input
              class="bottom-padding"
              label="Group Name"
              zpxInputName="group"
              [formGroup]="formGroup"
              [control]="groupInputFormControl">
            </app-zpx-input>

            <app-status-filter
              class="bottom-padding"
              label="Group Status"
              [formGroup]="formGroup"
              defaultOption="1">
            </app-status-filter>
            <div fxLayout="row" fxLayoutGap=".5em">
              <p>Show Inactive Groups</p>
              <mat-checkbox
                class="show-inactive-groups-box"
                [checked]="(showInactiveGroups | async)!"
                (change)="toggleActiveGroupOnly($event.checked)"></mat-checkbox>
            </div>
            <ul class="shadow-border group-list">
              <li
                class="group-list-item"
                *ngFor="let group of (filteredGroups$ | async)"
                [class.inactive-group]="!group.active"
                [class.selected-group]="group.isClicked"
                (click)="onGroupClick(group)">
                {{ group.name }}
              </li>
            </ul>
          </div>
          <div class="second-column shadow-border">
            <header>Visible for the following GTC locations</header>
            <ul>
              <li *ngFor="let loc of legacyLocations">
                <p>{{ loc.name }}</p>
                <mat-checkbox
                  [checked]="loc.isChecked"
                  (change)="onLegacyLocationCheckboxChange($event.checked, loc)"></mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
<p
  class="mat-error"
  style="text-align: center"
  *ngIf="isDuplicateGroupName$ | async">
  Group names must be unique.
</p>
<mat-dialog-actions>
  <div class="button-container">
    <button
      [disabled]="(this.selectedCompanyService.hasEditPermissions$  | async) === false "
      class="mat-stroked-button mat-button-base"
      [ngClass]="(this.selectedCompanyService.hasEditPermissions$  | async) === false ? 'mat-button-disabled'  : 'accent-button'"
      (click)="performGroupActions()">
      Save
    </button>
    <button
      class="mat-stroked-button mat-button-base white-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
  </div>
</mat-dialog-actions>
