import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field';

@Component({
  selector: 'app-zpx-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './zpx-input.component.html',
  styleUrls: ['./zpx-input.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ]
})
export class ZpxInputComponent implements OnInit {
  constructor() {}
  @Input() label: string;
  @Input() zpxInputName: string;
  @Input() control: FormControl = null;
  @Input() formGroup: FormGroup;

  ngOnInit(): void {
    this.formGroup.addControl(this.zpxInputName, this.control);
  }
}
