import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { ImportPreviewTableComponent } from '../import-preview-table/import-preview-table.component';

@Component({
  selector: 'app-import-preview',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    ImportPreviewTableComponent
  ],
  templateUrl: './import-preview.component.html',
  styleUrls: ['./import-preview.component.scss']
})
export class ImportPreviewComponent implements OnInit {
  @Input() frontendValidationState;
  @Input() backendValidationState;
  @Output() cancel = new EventEmitter<string>();

  errors = new BehaviorSubject(undefined); // eventually set this up to accept FE and BE validation states

  updateCount = 5;
  newCount = 1;
  unchangedCount = 6;
  constructor() {}

  ngOnInit(): void {
    console.log(this.frontendValidationState);
    console.log(this.backendValidationState);
    this.errors.next(!this.frontendValidationState?.valid);
  }

  goBack() {
    this.cancel.emit();
  }

  saveImport() {
    console.log('SAVE IMPORTS');
  }
}
