export enum PASSHOLDER_TYPES {
  STUDENT = 'student',
  AIDE = 'aide',
  DRIVER = 'driver'
}

export interface ZpxApiCustomColumnParams {
  division_id?: string;
  passholder_type_id: string;
}
export interface ZpxApiGetPassholderCommonColumnsParams {
  division_id?: string;
}

export interface ZpxGroupDivision {
  zpx_group_id: string;
  legacy_location_division_id: string;
}

export interface ZpxGroupDivisionExtended extends ZpxGroupDivision {
  id: string;
  company_id: string;
  division_id: string;
  last_updated_ts: string;
}

export interface ZpxGroupDivisionHttpResponseBody {
  body: ZpxGroupDivisionExtended[] | string[];
  status: number;
}
export interface ZpxApiGetPassholderCommonColumnsResponse {
  passholder_type: PASSHOLDER_TYPES;
  first_names?: string[];
  last_names?: string[];
  exsids?: string[];
  pass_numbers?: string[];
}

export interface ZpxApiGetCustomTypeColumnValuesResponse {
  custom_type_column_id: string;
  values: string[];
}

export interface PassholderPatchBody {
  passholder_type_id?: string;
  first_name?: string;
  last_name?: string;
  zpx_group_id?: string;
  active?: boolean;
  pass_number?: string;
  custom_type_values_update?: { column_id: string; value: string }[];
  zpx_id?: string;
}

export enum PASSHOLDER_COLUMN_HEADERS {
  CARD_NUMBER = 'CARD NUMBER',
  CARD_STATUS = 'CARD STATUS',
  LAST_NAME = 'LAST NAME',
  FIRST_NAME = 'FIRST NAME',
  UNIQUE_ID = 'UNIQUE ID',
  GROUP_NAME = 'GROUP NAME',
  CARD_COUNT = 'CARD COUNT',
  LAST_UPDATED = 'LAST UPDATED'
}

export enum PASSHOLDER_COMMON_COLUMNS {
  FIRST_NAMES = 'first-names',
  LAST_NAMES = 'last-names',
  EXSIDS = 'exsids',
  NUMBERS = 'numbers'
}
export enum PASSHOLDER_EVENT_COLUMN_HEADERS {
  LAST_NAME = 'LAST NAME',
  FIRST_NAME = 'FIRST NAME',
  CARD_NUMBER = 'CARD NUMBER',
  CH_TYPE = 'CH TYPE',
  UNIQUE_ID = 'UNIQUE ID',
  ODOMETER = 'ODOMETER',
  DATE = 'DATE',
  TIME = 'TIME',
  EVENT_TYPE = 'EVENT_TYPE'
}

export enum STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export interface Pass {
  id?: string;
  company_id?: string;
  division_id?: string;
  passholder_id?: string;
  pass_type_id?: string;
  number: string;
  insert_ts: string;
  deactivate_ts?: string;
  active?: boolean;
  ignored?: boolean;
  ignored_ts?: string;
  last_updated_ts?: string;
}

export interface PassholderForTable {
  card_number: string;
  card_status: STATUSES;
  last_name: string;
  first_name: string;
  unique_id: string;
  group_name: string;
  card_count: number;
  other_cards: Pass[];
  last_updated_ts: Date;
  zpx_id: string;
  class?: string;
  type?: PASSHOLDER_TYPES;
  active?: boolean;
}

export interface PassholderEventForTable {
  last_name: string;
  first_name: string;
  card_number: string;
  ch_type: string;
  card_status: STATUSES;
  unique_id: string;
  odometer: string;
  datetime: Date;
  event_type: string;
}
export interface ZpxApiPassholderParams {
  division_id?: string;
  passholder_type_string: PASSHOLDER_TYPES;
  limit?: number;
  offset?: number;
}

export interface ZpxApiPassholderEventParams {
  division_id?: string;
  passholder_type_string: PASSHOLDER_TYPES;
  limit?: number;
  offset?: number;
}

export interface ZpxApiPassholderGetReportBody {
  passholder?: string[];
  active?: boolean[];
  first_name?: string[];
  last_name?: string[];
  exsid?: string[];
  pass_number?: string[];
  group?: string[]; // uuid
  custom_columns?: ZpxApiPassholderGetReportBodyCustomColumns;
}
export interface ZpxApiPassholderGetReportBodyCustomColumns {
  [key: string]: string[]; // string of uuids
}

export interface ZpxFrontendPageParams {
  page: number;
  per_page: number;
}

export interface Group {
  active: boolean;
  company_id: string;
  division_id: string;
  gtc_zpass_location_id: number;
  id: string;
  name: string;
  last_updated_ts: string;
  isClicked?: boolean;
}

export interface GroupPatchBody {
  active: boolean;
  name: string;
}

export interface CustomTypeColumn {
  id?: string;
  company_id?: string;
  division_id?: string;
  name: string;
  passholder_type_id?: string;
  sequence: number;
  last_updated_ts?: string;
  value?: string;
  gtc_zpass_custom_type_column_id?: number;
}
export interface Passholder {
  active?: boolean;
  company_id: string;
  division_id: string;
  exsid: string;
  first_name: string;
  id: string;
  last_name: string;
  last_updated_ts: string;
  group_name: string;
  passes: any;
  custom_columns: any;
  zpx_group_id?: string;
  gtc_zpass_person_id?: number;
  passholder_type_id?: string;
}
export interface PassholderEvent {
  last_name: string;
  first_name: string;
  card_number: string;
  ch_type?: string;
  unique_id: string;
  odometer: string;
  datetime: string;
  event_type: string;
}

export interface CustomTypeValue {
  id: string;
  company_id: string;
  division_id: string;
  passholder_type_id: string;
  custom_type_column_id: string;
  last_updated_ts: string;
  value: string;
  passholder_id: string;
}
export interface PassholdersReportHttpResponseBody {
  total_count: number;
  count: number;
  offset: number;
  data: Passholder[];
}

export interface PassholderEventsReportHttpResponseBody {
  total_count: number;
  count: number;
  offset;
  data: PassholderEvent[];
}

export interface PassholderType {
  id: string;
  name: PASSHOLDER_TYPES;
}

export enum ZpxApiPatchParamNames {
  ACTIVE = 'active',
  GROUP = 'zpx_group_id',
  TYPE = 'passholder_type_id',
  LAST_NAME = 'last_name',
  FIRST_NAME = 'first_name',
  PASS_NUMBER = 'pass_number'
}

export enum ZpxApiFilterNames {
  DIVISION_ID = 'divisionId',
  STATUS = 'status',
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  PASS_NUMBER = 'passNumber',
  UNIQUE_ID = 'uniqueId',
  GROUP = 'groupName'
}
