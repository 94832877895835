import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from '@src/app/app.service';
import { PassholderTypeFilterComponent } from '../passholder-type-filter/passholder-type-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Papa, ParseError } from 'ngx-papaparse';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomTypeColumn } from '@src/app/models/zpx-api.model';
import { CustomColumnDialogComponent } from './custom-column-dialog/custom-column-dialog.component';
import _ from 'lodash-es';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { ImportPreviewComponent } from './import-preview/import-preview.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-import-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    PassholderTypeFilterComponent,
    DragDropModule,
    CustomColumnDialogComponent,
    ImportPreviewComponent
  ],
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit, AfterViewInit {
  constructor(
    private appService: AppService,
    private papa: Papa,
    private dialog: MatDialog,
    private zpxApiService: ZpxApiService
  ) {}

  data = inject<any>(MAT_DIALOG_DATA);
  formGroup = new FormGroup({});
  formControl = new FormControl('import');
  typeDefaultOption$ = this.appService.passholderTypeId$;
  customColumnsChanged = false;
  validationComplete = false;
  frontendValidationState: {
    valid: boolean;
    errors?: any;
  };
  backendValidationState: {
    // this is meant to be changed once PUP-5005 is complete
    valid: boolean;
    errors?: any;
    summary?: {
      added: any;
      updated: any;
      unchanged: any;
    };
  };
  deleteIconClass = {
    'slightly-bigger': false
  };
  downloadCsvLinkStyles = {
    'text-decoration': 'underline',
    cursor: 'pointer'
  };

  standardColumns = [
    'Card No',
    'Card Status',
    'Last Name',
    'First Name',
    'Unique ID',
    'Group Name'
  ];

  editableCustomColumns: CustomTypeColumn[] = [];
  pristineCustomColumns: CustomTypeColumn[] = [];

  reorderCustomColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.editableCustomColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.checkCustomColumnsChanged();
  }

  checkCustomColumnsChanged() {
    if (_.isEqual(this.editableCustomColumns, this.pristineCustomColumns)) {
      this.customColumnsChanged = false;
    } else {
      this.customColumnsChanged = true;
    }
  }

  removeCustomColumn(event: CdkDragDrop<string[]>) {
    this.editableCustomColumns.splice(event.previousIndex, 1);
    this.decreaseDeleteIconSize();
    this.checkCustomColumnsChanged();
  }

  increaseDeleteIconSize() {
    this.deleteIconClass['slightly-bigger'] = true;
  }
  decreaseDeleteIconSize() {
    this.deleteIconClass['slightly-bigger'] = false;
  }

  downloadCSV(type: 'example' | 'complete') {
    console.log(`CLICK DOWNLOAD ${type} CSV`);
    if (type == 'example') {
      this.generateExampleCsv();
    }
  }

  generateExampleCsv() {
    const exampleCsV = this.papa.unparse([this.getExpectedColumnHeaders()]);
    const newBlob = new Blob([exampleCsV], { type: 'text/csv' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'example.csv';
    link.click();
  }

  openCustomColumnDialog(
    action: 'edit' | 'add',
    customColumn: CustomTypeColumn = undefined
  ) {
    this.dialog
      .open(CustomColumnDialogComponent, {
        data: {
          action: action,
          customCol: customColumn
        },
        disableClose: true
      })
      .afterClosed()
      .subscribe((data) => {
        this.handleCustomColumnDialogChange(data);
        this.checkCustomColumnsChanged();
      });
  }

  saveCustomColumns() {
    console.log('CLICK SAVE');
  }

  handleCustomColumnDialogChange(data) {
    if (data.action === 'edit') {
      this.editableCustomColumns[
        this.editableCustomColumns.findIndex((c) => c.id == data.customCol.id)
      ].name = data.newColName;
    }
    if (data.action === 'add') {
      const newCustCol = { name: data.newColName } as CustomTypeColumn;
      this.editableCustomColumns.push(newCustCol);
    }
  }

  csvInputChange(fileInputEvent: Event) {
    const element = fileInputEvent.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (fileList) {
      const file: File = fileList[0];
      const options = {
        complete: (results) => {
          this.frontendValidationState =
            this.performFrontendValidation(results);

          if (this.frontendValidationState.valid) {
            this.performBackendValidation();
          } else {
            this.validationComplete = true;
          }
        }
      };
      this.papa.parse(file, options);
    }
  }

  performBackendValidation() {
    // this doesn't need to get called unless the file import passes frontend validation

    timer(1000).subscribe(() => {
      // this is mocking the backend validation
      this.backendValidationState = {
        valid: true,
        summary: {
          added: 5,
          updated: 1,
          unchanged: 3
        }
      };
      this.validationComplete = true;
    });
  }

  performFrontendValidation(parsedCsvResult: {
    data?: string[][];
    errors?: ParseError[];
    meta?: {};
  }) {
    const { data, errors } = parsedCsvResult;
    let validationState = {
      valid: true,
      errors: []
    };
    if (errors.length) {
      validationState.valid = false;
      validationState.errors = validationState.errors.concat(
        errors.map((error) => new ValidationError(error.code, error.message))
      );
      return validationState;
    }
    if (data.length) {
      const csvHeaders = data[0].map((col) => col.toLowerCase());
      const expectedHeaders = this.getExpectedColumnHeaders().map((col) =>
        col.toLowerCase()
      );
      const missingHeaders = _.difference(expectedHeaders, csvHeaders);
      const extraHeaders = _.difference(csvHeaders, expectedHeaders);
      if (missingHeaders.length) {
        validationState.valid = false;
        validationState.errors = validationState.errors.concat(
          missingHeaders.map(
            (missingHeader) =>
              new ValidationError('Missing Column', missingHeader)
          )
        );
      }
      if (extraHeaders.length) {
        validationState.valid = false;
        validationState.errors = validationState.errors.concat(
          extraHeaders.map(
            (extraHeader) => new ValidationError('Extra Column', extraHeader)
          )
        );
      }
    }

    return validationState;
  }

  getExpectedColumnHeaders(): string[] {
    console.log('called');
    return this.standardColumns.concat(
      this.pristineCustomColumns.map((cc) => cc.name)
    );
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        switchMap((changes) => {
          const pTypeId = changes['passholder_type_id'];
          if (pTypeId !== undefined) {
            return this.zpxApiService.getCustomColumns({
              refreshAcrossApp: false,
              passholderTypeId: pTypeId
            });
          }
        }),
        map((customCols) => {
          this.editableCustomColumns = _.cloneDeep(customCols);
          this.pristineCustomColumns = _.cloneDeep(customCols);
          this.checkCustomColumnsChanged();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    // this is a really annoying hack because the pattern library SearchableDropdownComponent adds its own
    // control even when you try very hard for it to not.
    // https://gitlab.com/ZonarSystems/pattern-library/zonar-ui-searchable-dropdown/-/blob/main/projects/zonar-ui-searchable-dropdown/README.md
    // search 'searchFormControlName'
    this.formGroup.removeControl('undefined');
  }
}

class ValidationError {
  errorType: string;
  errorDetail: string;
  constructor(errorType: string, errorDetail: string) {
    this.errorType = errorType;
    this.errorDetail = errorDetail;
  }
}
