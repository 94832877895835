<div id="passholder-filter-bar">
  <app-passholder-type-filter
    [formGroup]="formGroup"
    [defaultOption]="( typeDefaultOption$ | async )">
  </app-passholder-type-filter>

  <app-passholder-group-filter
    [formGroup]="formGroup"
    [defaultOption]="( groupDefaultOption$ | async )">
  </app-passholder-group-filter>

  <app-status-filter
    [formGroup]="formGroup"
    [defaultOption]="statusDefaultOption">
  </app-status-filter>

  <app-zpx-input
    *ngFor="let si of standardInputs | keyvalue"
    [label]="si.value.label"
    [zpxInputName]="si.key"
    [control]="si.value.control"
    [formGroup]="formGroup">
  </app-zpx-input>

  <app-zpx-input
    *ngFor="let ci of customColumnInputs$ | async | keyvalue"
    [label]="ci.value.label"
    [zpxInputName]="ci.key"
    [control]="ci.value.control"
    [formGroup]="formGroup">
  </app-zpx-input>
</div>
