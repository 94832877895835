<mat-dialog-content *ngIf="(errors | async ) === true; else previewTabs">
  Oops! The following errors were found while trying to stage your import.
  Please correct the errors and reimport.
  <ul>
    <li *ngFor="let error of frontendValidationState.errors">
      {{ error.errorType }} - {{error.errorDetail}}
    </li>
  </ul>
</mat-dialog-content>

<ng-template #previewTabs>
  <mat-dialog-content>Review and Save Updates</mat-dialog-content>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label> Updates {{updateCount}} </ng-template>
      <ng-template matTabContent>
        <app-import-preview-table></app-import-preview-table>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> New {{newCount}} </ng-template>
      <ng-template matTabContent>
        <app-import-preview-table></app-import-preview-table>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label> No Change {{unchangedCount}} </ng-template>
      <ng-template matTabContent>
        <app-import-preview-table></app-import-preview-table>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<mat-dialog-actions [style]="'margin-top:auto; padding-bottom:1.5rem'">
  <div class="button-container" [style.margin]="'revert'">
    <button
      class="mat-stroked-button mat-button-base accent-button"
      (click)="goBack()">
      Previous Step
    </button>
    <button
      *ngIf="(errors | async) === false"
      class="mat-stroked-button mat-button-base accent-button"
      (click)="saveImport()">
      Save
    </button>
    <button
      class="mat-stroked-button mat-button-base white-button"
      mat-dialog-close>
      Cancel
    </button>
  </div>
</mat-dialog-actions>
