<h2 mat-dialog-title>Import Cardholders</h2>

<ng-container *ngIf="!validationComplete; else uploadedPreview">
  <mat-dialog-content>
    <h3 [style.padding-right]="'2%'">Step 1:</h3>
    <app-passholder-type-filter
      [formGroup]="formGroup"
      [defaultOption]="( typeDefaultOption$ | async )"
      [label]="'Select card holder type'">
    </app-passholder-type-filter>
  </mat-dialog-content>
  <mat-dialog-content>
    <h3>Step 2: <span>Set up your custom columns</span></h3>
    <p>
      Custom columns allow you to store additional details for each cardholder
      type. To edit a custom column, double click the column name. Click and
      drag to reorder columns or remove a column.
    </p>
    <p>
      <strong>Note:</strong> Each card holder is required to have a Unique ID,
      the first 6 columns are required and can’t be reordered or removed.
    </p>
  </mat-dialog-content>
  <div id="import-column-drag-n-drop-container">
    <div>
      <ng-container *ngFor="let col of standardColumns">
        <span>{{col}}</span>
      </ng-container>
      <div
        cdkDropList
        #custColList="cdkDropList"
        (cdkDropListDropped)="reorderCustomColumns($event)"
        [cdkDropListConnectedTo]="[deleteList]"
        id="custom-column-drop-list"
        cdkDropListOrientation="horizontal">
        <ng-container *ngFor="let col of editableCustomColumns">
          <span
            class="custom-column-drag-item"
            cdkDrag
            (dblclick)="openCustomColumnDialog('edit', col)">
            {{col.name}}
          </span>
        </ng-container>
      </div>
      <span
        id="add-custom-column-button"
        (click)="openCustomColumnDialog('add')">
        +
      </span>
    </div>
    <div
      cdkDropList
      #deleteList="cdkDropList"
      id="delete-custom-column-drop-list"
      [cdkDropListConnectedTo]="[custColList]"
      (cdkDropListEntered)="increaseDeleteIconSize()"
      (cdkDropListExited)="decreaseDeleteIconSize()"
      (cdkDropListDropped)="removeCustomColumn($event)">
      <mat-icon [ngClass]="deleteIconClass">delete_outline</mat-icon
      ><span>Drag here to remove</span>
    </div>
  </div>

  <mat-dialog-content>
    <h3>Step 3: <span>Upload CSV file to review updates</span></h3>
    <p>
      Your CSV filed requires a header row and should include all required
      custom columns. Download an
      <span [ngStyle]="downloadCsvLinkStyles" (click)="downloadCSV('example')">
        example
      </span>
      or
      <span [ngStyle]="downloadCsvLinkStyles" (click)="downloadCSV('complete')">
        complete
      </span>
      CSV file.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="mat-stroked-button mat-button-base accent-button"
      (click)="csvInput.click()">
      Upload and Review
    </button>
    <input
      #csvInput
      type="file"
      hidden="true"
      onclick="this.value=null"
      (change)="csvInputChange($event)"
      accept=".csv" />
  </mat-dialog-actions>

  <mat-dialog-actions [style]="'margin-top:auto; padding-bottom:1.5rem'">
    <div class="button-container" [style.margin]="'revert'">
      <button
        class="mat-stroked-button mat-button-base"
        [ngClass]="!customColumnsChanged ? 'mat-button-disabled':  'accent-button'"
        [disabled]="!customColumnsChanged"
        (click)="saveCustomColumns()">
        Save Columns
      </button>
      <button
        class="mat-stroked-button mat-button-base white-button"
        mat-dialog-close>
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>

<ng-template #uploadedPreview>
  <app-import-preview
    (cancel)="validationComplete=false"
    [frontendValidationState]="frontendValidationState"
    [backendValidationState]="backendValidationState">
  </app-import-preview>
</ng-template>
