import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatInput, MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormControlStatus,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-column-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './custom-column-dialog.component.html',
  styleUrls: ['./custom-column-dialog.component.scss']
})
export class CustomColumnDialogComponent implements OnInit, AfterViewInit {
  formControl: FormControl;
  @ViewChild('customColumnNameInput') custColNameInput: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<CustomColumnDialogComponent>,
    private cd: ChangeDetectorRef
  ) {
    this.formControl = new FormControl(this.data.customCol?.name, [
      Validators.required
    ]);
  }
  data = inject<any>(MAT_DIALOG_DATA);
  buttonActionMap = {
    edit: 'Update',
    add: 'Add'
  };
  disabled = new BehaviorSubject(true);

  saveChange() {
    const data = { ...this.data };
    data['newColName'] = this.formControl.value;
    this.dialogRef.close(data);
  }

  ngOnInit(): void {
    this.formControl.statusChanges
      .pipe(
        map((status: FormControlStatus) => {
          if (status == 'INVALID') {
            this.disabled.next(true);
          } else {
            this.disabled.next(false);
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.custColNameInput.nativeElement.focus();
    this.cd.detectChanges();
  }
}
