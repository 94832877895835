import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDropdownComponent } from '../filter-dropdown/filter-dropdown.component';
import { FormControl, FormGroup } from '@angular/forms';
import {
  PASSHOLDER_TYPES,
  ZpxApiPatchParamNames
} from '@src/app/models/zpx-api.model';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-passholder-type-filter',
  standalone: true,
  imports: [CommonModule, FilterDropdownComponent],
  templateUrl: './passholder-type-filter.component.html',
  styleUrls: ['./passholder-type-filter.component.scss']
})
export class PassholderTypeFilterComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() defaultOption: string | Observable<string> = null;
  @Input() label = 'Type';
  formControlName = ZpxApiPatchParamNames.TYPE;

  options: DropdownOption[] = null;

  constructor(private zpxApiService: ZpxApiService) {}

  setPassholderTypeName(name: PASSHOLDER_TYPES) {
    if (name === PASSHOLDER_TYPES.STUDENT) {
      return 'Rider';
    } else if (name === PASSHOLDER_TYPES.AIDE) {
      return 'Aide';
    }
    return 'Driver';
  }

  setPassholderTypeOpts(): void {
    this.zpxApiService
      .getPassholderTypes()
      .pipe(
        filter((types) => Boolean(types.length)),
        first(),
        map((types) => {
          return types.map((type) => {
            return {
              title: this.setPassholderTypeName(type.name),
              value: type.id
            } as DropdownOption;
          });
        })
      )
      .subscribe((options) => {
        this.options = options;
        if (this.defaultOption) {
          this.formGroup.controls[this.formControlName].setValue(
            this.defaultOption,
            {
              emitEvent: false,
              onlySelf: true
            }
          );
        }
      });
  }

  ngOnInit(): void {
    this.formGroup.addControl(this.formControlName, new FormControl());
    this.setPassholderTypeOpts();
  }
}
