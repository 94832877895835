import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserCompany } from '@app/models/user.company.model';

export const mockCompany: UserCompany = {
  title: 'default',
  value: 'default'
};

@Injectable({
  providedIn: 'root'
})
export class SelectedCompanyService {
  private _currentCompanyId$ = new BehaviorSubject<UserCompany>(mockCompany);
  private _userPerms$ = new BehaviorSubject<Array<string>>(['default']);
  constructor() {}

  public setCompanyIdFromSideNav(values: UserCompany) {
    this._currentCompanyId$.next(values);
  }

  public getCompanyFromSideNav(): Observable<UserCompany> {
    return this._currentCompanyId$
      .asObservable()
      .pipe(filter((values) => values !== null && values.value !== 'default'));
  }

  getCompanyId(): Observable<string> {
    return this.getCompanyFromSideNav().pipe(map((company) => company.value));
  }

  public setUserPermissions(values: string[]) {
    this._userPerms$.next(values);
  }

  getUserPermissions(): Observable<string[]> {
    return this._userPerms$;
  }

  hasEditPermissions$ = this.getUserPermissions().pipe(
    map((permissionValues: string[]) =>
      permissionValues.includes('zpass:edit:passholder')
    )
  );
}
