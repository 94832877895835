import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  CustomTypeColumn,
  Group,
  Passholder,
  PASSHOLDER_TYPES,
  PassholderForTable,
  PassholderPatchBody,
  PassholderType
} from './models/zpx-api.model';
import { LegacyLocation } from './models/entity-api.model';

/*

This is the highest level service that is used to share top level information via Behavior Subjects across the app for information that any component or other service needs without relying on deeply nested inputs/outputs or sending Behavior subjects from weird places

For example, the first member for this service is passholderType$  to share the passholer type across the app as different places need to know the type in order to fetch items specific to that type. Please use this service accordingly for other top level information.

*/
@Injectable({
  providedIn: 'root'
})
export class AppService {
  passholderType$: BehaviorSubject<PASSHOLDER_TYPES> = new BehaviorSubject(
    null
  );

  passholderTypeId$: BehaviorSubject<string> = new BehaviorSubject(null);

  customColumns$: BehaviorSubject<CustomTypeColumn[]> = new BehaviorSubject(
    null
  );

  passholders$: BehaviorSubject<PassholderForTable[]> = new BehaviorSubject(
    null
  );

  passholderTypes$: BehaviorSubject<PassholderType[]> = new BehaviorSubject(
    null
  );

  groupsByNameAsc$: BehaviorSubject<Group[]> = new BehaviorSubject(null);

  patchedPassholder$: BehaviorSubject<Passholder> = new BehaviorSubject(null);

  // TODO use this during the division picker implementation
  selectedDivisionId$: BehaviorSubject<string> = new BehaviorSubject(null);
  // divisionId$ is probably the next high level info we need to share around the app when we get division picker

  divisions$: BehaviorSubject<{ name: string; id: string }[]> =
    new BehaviorSubject(null);

  legacyLocations$: BehaviorSubject<LegacyLocation[]> = new BehaviorSubject(
    null
  );

  constructor() {}
}
